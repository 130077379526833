
import "bootstrap/dist/css/bootstrap.css";

import { defineComponent } from "vue";
import { reactive, toRefs } from "vue";

import FAQ from "./FAQ.vue";

import { PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";

const faqs = [
    {
        Q: "I'm a Harvard student interested in research opportunities in your lab. Are there any available?",
        A: "Maybe: we do sometimes have slots available. Typically these positions require skills in machine learning or data visualization. Please reach out to us by mail.",
    },
    {
        Q: "I've been reading your papers, and I have an idea for an interesting follow-up. Can I mail you about it?",
        A: "Sure! We're definitely open to this kind of conversation, but the briefer and more concrete you can make your description, the more likely we'll have time to read it.",
    },
    {
        Q: "I'm a student, but not at Harvard. Can I work in your lab?",
        A: "Depending on where you are in your career, you could apply for the Ph.D. program or a post-doc. Other than that, we probably can't find a position: we generally don't supervise non-Harvard students. In particular, we don't supervise high school students.",
    },

    { Q: "Can I pay you to supervise a student?", A: "No." },
];

export default defineComponent({
    name: "Contact",
    components: { FAQ, PlusOutlined, MinusOutlined },
    setup() {
        const state = reactive({});

        return {
            ...toRefs(state),
            faqs,
        };
    },
    computed: {},
});
