
import "bootstrap/dist/css/bootstrap.css";

import { defineComponent } from "vue";
import { reactive, toRefs } from "vue";

import { PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";


export default defineComponent({
    name: "FAQ",
    props: ["faq"],
    components: { PlusOutlined, MinusOutlined },
    setup() {
        const state = reactive({
            open: false,
        });

        const toggleOpen = () => {
            state.open = !state.open;
        }

        return {
            ...toRefs(state),
            toggleOpen
        };
    },
    computed: {},
});
