
import "bootstrap/dist/css/bootstrap.css";

import { defineComponent } from "vue";

import { reactive, toRefs } from "vue";

function animateSvgLetters() {
    let delay: number = 200;

    const paths: NodeListOf<SVGElement> = document.querySelectorAll("svg .letter");
    const num = paths.length;

    paths.forEach((path: SVGElement, index: number) => {
        setTimeout(() => {
            if (index >= 15) {
                path.classList.add("wavy");
            }
        }, (num - index - 1) * 60 + delay);
    });
}

function stopAnimation() {
    const paths: NodeListOf<SVGElement> = document.querySelectorAll("svg .letter");
    paths.forEach((path: SVGElement) => {
        path.classList.remove("wavy");
    })
}

function myEventHandler(e: Event) {
    stopAnimation();
    animateSvgLetters();
}

export default defineComponent({
    name: "Logo",
    components: {},
    setup() {

        const state = reactive({});

        // onMounted(() => {
        //     animateSvgLetters();
        //     window.addEventListener("resize", myEventHandler);
        // });

        // onUnmounted(() => {
        //     stopAnimation();
        //     window.removeEventListener("resize", myEventHandler);
        // });

        return {
            ...toRefs(state),
        };
    },
});
