
import "bootstrap/dist/css/bootstrap.css";

import { defineComponent } from "vue";

import Logo from "./Logo.vue";
import Wind from "./Wind.vue";

import { reactive, toRefs } from "vue";

export default defineComponent({
    name: "Hello",
    components: { Logo, Wind },
    setup() {
        const state = reactive({});

        return {
            ...toRefs(state),
        };
    },
    computed: {},
});
