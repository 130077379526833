
import "bootstrap/dist/css/bootstrap.css";
import * as _ from "underscore";

import { defineComponent } from "vue";

import { reactive, toRefs } from "vue";

import { people, Person, Alum, alumni } from "./people";

// CURRENT MEMBERS
// sort by role
const roleOrder = ["Professor", "Postdoc", "PhD Student", "Undergraduate"]

const comparePeople = (a: Person, b: Person) => {
    // Compare by role
    if (roleOrder.indexOf(a.role) < roleOrder.indexOf(b.role)) return -1;
    if (roleOrder.indexOf(a.role) > roleOrder.indexOf(b.role)) return 1;

    // If roles are equal, compare by year
    if (a.year > b.year) return 1;
    if (a.year < b.year) return -1;

    // If years are equal, compare by first name
    const firstNameA = a.name.split(" ")[0] || "";
    const firstNameB = b.name.split(" ")[0] || "";
    return firstNameA.localeCompare(firstNameB);
}

// list of people
people.sort(comparePeople); // sort

// final person mapping
const finalPeopleList = people.map((x) => ({
    ...x,
    img: x.profile
        ? require(`@/assets/images/people/${x.profile}`)
        : require(`@/assets/images/people/placeholder.png`),
    hoverImg: x.alter ? require(`@/assets/images/alterego/${x.alter}`)
        : require(`@/assets/images/people/placeholder.png`),
}));

const compareAlumni = (a: Alum, b: Alum) => {

    // First, compare by role
    if (roleOrder.indexOf(a.prev) < roleOrder.indexOf(b.prev)) return -1;
    if (roleOrder.indexOf(a.prev) > roleOrder.indexOf(b.prev)) return 1;

    // If roles are equal, compare by year
    if (a.year > b.year) return -1;
    if (a.year < b.year) return 1;

    // If years are equal, compare by first name
    const firstNameA = a.name.split(" ")[0] || "";
    const firstNameB = b.name.split(" ")[0] || "";
    return firstNameA.localeCompare(firstNameB);
}

alumni.sort(compareAlumni); // sort

// final alumni mapping
const finalAlumList = alumni.map((x) => ({
    ...x,
    img: x.profile
        ? require(`@/assets/images/people/${x.profile}`)
        : require(`@/assets/images/people/placeholder.png`),
}));

export default defineComponent({
    name: "People",
    components: {},
    setup() {
        const state = reactive({});

        return {
            finalPeopleList,
            finalAlumList,
            ...toRefs(state),
        };
    },
    computed: {},
});
