
import "bootstrap/dist/css/bootstrap.css";

import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
    name: "Footer",
    setup() {
        const state = reactive({
            year: new Date().getFullYear(),
        });

        const footerLogo = require(`@/assets/logos/footerlogo.svg`);
        const harvardLogo = require(`@/assets/logos/harvardlogo.svg`);
        const toTop = require(`@/assets/backtotop.svg`);

        return {
            ...toRefs(state),
            footerLogo,
            harvardLogo,
            toTop,
        };
    },
    computed: {},
});
