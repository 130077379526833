
import "bootstrap/dist/css/bootstrap.css";
import { useHead } from "@vueuse/head";

import Publications from "./App/Publications/Publications.vue";
import Hello from "./App/Hello/Hello.vue";
import Projects from "./App/Projects/Projects.vue";
import Team from "./App/Team/Team.vue";
import Teaching from "./App/Teaching/Teaching.vue";
import Contact from "./App/Contact/Contact.vue";
import Footer from "./App/Footer.vue";
import Nav from "./App/Nav.vue";

import { defineComponent, computed, reactive, toRefs } from "vue";

export default defineComponent({
    name: "App",
    components: {
        Publications,
        Hello,
        Projects,
        Teaching,
        Team,
        Footer,
        Nav,
        Contact
    },
    setup() {
        const state = reactive({
        });

        const siteData = reactive({
            title: `Insight + Interaction Lab`,
            description: ``,
        });

        useHead({
            title: computed(() => siteData.title),
            meta: [
                {
                    name: `description`,
                    content: computed(() => siteData.description),
                },
                {
                    name: "keywords",
                    content: "",
                },
            ],
            link: [
                {
                    rel: "icon",
                    href: require(`@/assets/fav.svg`), // Use the imported favicon
                    type: "image/x-icon",
                },
            ],
        });

        return {
            ...toRefs(state),
        };
    },
});
