<template>
    <svg id="my-logo" width="100%" height="100%" viewBox="0 0 342 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_115_8)">
            <g class="letters">
                <path class="letter"
                    d="M333.49 82.7C337.84 82.7 341.13 86.24 341.13 90.71C341.13 95.18 337.84 98.71 333.49 98.71C331.55 98.71 329.8 98.02 328.47 96.84V96.99C328.47 97.93 327.75 98.68 326.81 98.68C325.87 98.68 325.15 97.93 325.15 96.99V78.66C325.15 77.72 325.88 77 326.81 77C327.74 77 328.47 77.72 328.47 78.66V84.61C329.8 83.4 331.55 82.7 333.49 82.7ZM337.78 90.71C337.78 87.9 335.72 85.78 333.12 85.78C330.52 85.78 328.56 87.81 328.47 90.5V90.71C328.47 93.52 330.53 95.63 333.12 95.63C335.71 95.63 337.78 93.52 337.78 90.71Z"
                    fill="white" />
                <path class="letter"
                    d="M321.29 84.39V96.99C321.29 97.93 320.56 98.68 319.63 98.68C318.7 98.68 317.97 97.93 317.97 96.99V96.84C316.64 98.02 314.89 98.71 312.95 98.71C308.6 98.71 305.31 95.18 305.31 90.71C305.31 86.24 308.6 82.7 312.95 82.7C314.89 82.7 316.64 83.4 317.97 84.61V84.39C317.97 83.46 318.69 82.73 319.63 82.73C320.57 82.73 321.29 83.46 321.29 84.39ZM317.97 90.71V90.5C317.85 87.81 315.85 85.78 313.31 85.78C310.77 85.78 308.66 87.9 308.66 90.71C308.66 93.52 310.72 95.63 313.31 95.63C315.9 95.63 317.97 93.52 317.97 90.71Z"
                    fill="white" />
                <path class="letter"
                    d="M302.08 78.66V96.99C302.08 97.93 301.36 98.68 300.42 98.68C299.48 98.68 298.76 97.93 298.76 96.99V78.66C298.76 77.72 299.48 77 300.42 77C301.36 77 302.08 77.72 302.08 78.66Z"
                    fill="white" />
                <path class="letter"
                    d="M287.38 89.74V96.99C287.38 97.93 286.62 98.68 285.72 98.68C284.82 98.68 284.06 97.93 284.06 96.99V89.62C284.06 87.11 282.51 85.78 280.58 85.78C278.65 85.78 277.11 87.11 277.11 89.62V96.99C277.11 97.93 276.38 98.68 275.45 98.68C274.52 98.68 273.79 97.93 273.79 96.99V89.74C273.79 85.42 276.78 82.7 280.58 82.7C284.38 82.7 287.38 85.42 287.38 89.74Z"
                    fill="white" />
                <path class="letter"
                    d="M262.55 82.7C267.18 82.7 270.56 86.24 270.56 90.71C270.56 95.18 267.18 98.71 262.55 98.71C257.92 98.71 254.55 95.18 254.55 90.71C254.55 86.24 257.96 82.7 262.55 82.7ZM267.21 90.71C267.21 87.99 265.21 85.78 262.55 85.78C259.89 85.78 257.9 87.99 257.9 90.71C257.9 93.43 259.9 95.63 262.55 95.63C265.2 95.63 267.21 93.4 267.21 90.71Z"
                    fill="white" />
                <g class="letter">
                    <path
                        d="M251.32 84.39V96.99C251.32 97.93 250.6 98.68 249.66 98.68C248.72 98.68 248 97.93 248 96.99V84.39C248 83.46 248.72 82.73 249.66 82.73C250.6 82.73 251.32 83.46 251.32 84.39Z"
                        fill="white" />
                    <path
                        d="M249.66 80.98C250.742 80.98 251.62 80.1025 251.62 79.02C251.62 77.9375 250.742 77.06 249.66 77.06C248.578 77.06 247.7 77.9375 247.7 79.02C247.7 80.1025 248.578 80.98 249.66 80.98Z"
                        fill="white" />
                </g>
                <path class="letter"
                    d="M243.59 83.01C244.44 83.01 245.13 83.67 245.13 84.55C245.13 85.43 244.44 86.09 243.59 86.09H240.06V91.55C240.06 94.21 241.54 95.57 243.59 95.57C244.44 95.57 245.13 96.27 245.13 97.11C245.13 97.95 244.44 98.68 243.59 98.68C239.7 98.68 236.74 95.93 236.74 91.55V86.09H235.65C234.8 86.09 234.11 85.39 234.11 84.55C234.11 83.71 234.8 83.01 235.65 83.01H236.74V80.74C236.74 79.8 237.46 79.08 238.4 79.08C239.34 79.08 240.06 79.8 240.06 80.74V83.01H243.59Z"
                    fill="white" />
                <path class="letter"
                    d="M232.27 85.15C232.57 85.51 232.75 85.94 232.75 86.3C232.75 87.2 232.06 87.84 231.12 87.84C230.46 87.84 230.12 87.51 229.7 87.14C228.95 86.39 227.92 85.78 226.47 85.78C223.87 85.78 221.82 87.96 221.82 90.71C221.82 93.46 223.87 95.63 226.47 95.63C227.86 95.63 228.95 95.03 229.67 94.27C230.09 93.91 230.46 93.52 231.12 93.52C232.06 93.52 232.75 94.18 232.75 95.12C232.75 95.45 232.57 95.87 232.27 96.2C231.12 97.59 229.04 98.71 226.47 98.71C221.88 98.71 218.47 95.18 218.47 90.71C218.47 86.24 221.88 82.7 226.47 82.7C229.01 82.7 231.12 83.79 232.27 85.15Z"
                    fill="white" />
                <path class="letter"
                    d="M215.24 84.39V96.99C215.24 97.93 214.51 98.68 213.58 98.68C212.65 98.68 211.92 97.93 211.92 96.99V96.84C210.59 98.02 208.84 98.71 206.9 98.71C202.55 98.71 199.26 95.18 199.26 90.71C199.26 86.24 202.55 82.7 206.9 82.7C208.84 82.7 210.59 83.4 211.92 84.61V84.39C211.92 83.46 212.64 82.73 213.58 82.73C214.52 82.73 215.24 83.46 215.24 84.39ZM211.92 90.71V90.5C211.8 87.81 209.8 85.78 207.26 85.78C204.72 85.78 202.61 87.9 202.61 90.71C202.61 93.52 204.67 95.63 207.26 95.63C209.85 95.63 211.92 93.52 211.92 90.71Z"
                    fill="white" />
                <path class="letter"
                    d="M196.55 82.7C197.39 82.7 198.09 83.37 198.09 84.24C198.09 85.11 197.39 85.78 196.55 85.78C194.55 85.78 193.07 87.11 193.07 89.77V96.99C193.07 97.93 192.35 98.68 191.41 98.68C190.47 98.68 189.75 97.93 189.75 96.99V89.77C189.75 85.48 192.62 82.7 196.55 82.7Z"
                    fill="white" />
                <path class="letter"
                    d="M186.64 95.12C186.64 95.45 186.46 95.87 186.16 96.2C185.01 97.59 182.93 98.71 180.36 98.71C175.8 98.71 172.36 95.18 172.36 90.71C172.36 86.24 175.77 82.7 180.36 82.7C182.72 82.7 184.56 83.64 185.59 84.58C185.95 84.91 186.19 85.36 186.19 85.85C186.19 86.27 186.07 86.63 185.71 87.05L181.05 92.58C180.72 92.97 180.36 93.12 179.91 93.12C179.23 93.12 178.69 92.77 178.46 92.22C178.38 92.03 178.34 91.83 178.34 91.61C178.34 91.25 178.43 90.89 178.7 90.62L182.35 86.33C181.84 85.97 181.02 85.78 180.36 85.78C177.76 85.78 175.71 87.96 175.71 90.71C175.71 91.39 175.84 92.04 176.07 92.63C176.76 94.39 178.39 95.63 180.36 95.63C181.78 95.63 182.84 95.03 183.56 94.27C184.04 93.88 184.38 93.52 185.01 93.52C185.95 93.52 186.64 94.18 186.64 95.12Z"
                    fill="white" />
                <path class="letter"
                    d="M169.13 83.01C169.97 83.01 170.67 83.67 170.67 84.55C170.67 85.43 169.97 86.09 169.13 86.09H165.59V91.55C165.59 94.21 167.07 95.57 169.13 95.57C169.97 95.57 170.67 96.27 170.67 97.11C170.67 97.95 169.97 98.68 169.13 98.68C165.23 98.68 162.27 95.93 162.27 91.55V86.09H161.18C160.34 86.09 159.64 85.39 159.64 84.55C159.64 83.71 160.34 83.01 161.18 83.01H162.27V80.74C162.27 79.8 163 79.08 163.93 79.08C164.86 79.08 165.59 79.8 165.59 80.74V83.01H169.13Z"
                    fill="white" />
                <path class="letter"
                    d="M157.89 89.74V96.99C157.89 97.93 157.14 98.68 156.23 98.68C155.32 98.68 154.57 97.93 154.57 96.99V89.62C154.57 87.11 153.03 85.78 151.1 85.78C149.17 85.78 147.63 87.11 147.63 89.62V96.99C147.63 97.93 146.9 98.68 145.96 98.68C145.02 98.68 144.3 97.93 144.3 96.99V89.74C144.3 85.42 147.29 82.7 151.1 82.7C154.91 82.7 157.89 85.42 157.89 89.74Z"
                    fill="white" />
                <g class="letter">
                    <path
                        d="M140.44 84.39V96.99C140.44 97.93 139.71 98.68 138.78 98.68C137.85 98.68 137.12 97.93 137.12 96.99V84.39C137.12 83.46 137.84 82.73 138.78 82.73C139.72 82.73 140.44 83.46 140.44 84.39Z"
                        fill="white" />
                    <path
                        d="M138.78 77.06C139.86 77.06 140.74 77.93 140.74 79.02C140.74 80.11 139.86 80.98 138.78 80.98C137.7 80.98 136.81 80.08 136.81 79.02C136.81 77.96 137.69 77.06 138.78 77.06Z"
                        fill="white" />
                </g>
                <path class="letter"
                    d="M124.53 86.21C125.4 86.21 126.13 86.93 126.13 87.81C126.13 88.69 125.4 89.41 124.53 89.41H121.27V92.55C121.27 93.49 120.51 94.27 119.54 94.27C118.57 94.27 117.82 93.49 117.82 92.55V89.41H114.56C113.68 89.41 112.96 88.71 112.96 87.84C112.96 86.97 113.68 86.21 114.56 86.21H117.82V83.07C117.82 82.13 118.58 81.35 119.54 81.35C120.5 81.35 121.27 82.13 121.27 83.07V86.21H124.53Z"
                    fill="white" />
                <path class="letter"
                    d="M337.26 15.35C339.37 15.35 341.11 17.01 341.11 19.2C341.11 21.39 339.37 23.04 337.26 23.04H328.44V36.69C328.44 43.32 332.14 46.71 337.26 46.71C339.37 46.71 341.11 48.44 341.11 50.55C341.11 52.66 339.37 54.48 337.26 54.48C327.54 54.48 320.15 47.62 320.15 36.69V23.04H317.44C315.33 23.04 313.59 21.31 313.59 19.2C313.59 17.09 315.33 15.35 317.44 15.35H320.15V9.69999C320.15 7.35999 321.96 5.54999 324.29 5.54999C326.62 5.54999 328.44 7.35999 328.44 9.69999V15.35H337.26Z"
                    fill="white" />
                <path class="letter"
                    d="M309.22 32.16V50.25C309.22 52.59 307.34 54.48 305.08 54.48C302.82 54.48 300.93 52.59 300.93 50.25V31.86C300.93 25.53 297.09 22.29 292.26 22.29C287.43 22.29 283.59 25.53 283.59 31.86V50.25C283.59 52.59 281.78 54.48 279.45 54.48C277.12 54.48 275.3 52.59 275.3 50.25V4.49998C275.3 2.15998 277.11 0.349976 279.45 0.349976C281.79 0.349976 283.59 2.15998 283.59 4.49998V17.92C286 16.03 289.25 14.6 293.17 14.6C302.14 14.6 309.22 21.38 309.22 32.16Z"
                    fill="white" />
                <path class="letter"
                    d="M265.66 18.82V48.9C265.66 60.2 256.61 68.87 245.3 68.87C238.9 68.87 233.85 66.16 230.91 62.84C230.15 61.94 229.7 60.88 229.7 60.05C229.7 57.72 231.51 56.13 233.77 56.13C235.43 56.13 236.33 57.11 237.39 58.02C239.42 59.9 241.99 61.18 245.3 61.18C251.86 61.18 256.84 56.13 257.29 49.88C253.97 52.82 249.68 54.55 244.85 54.55C234 54.55 225.78 45.73 225.78 34.57C225.78 23.41 234 14.6 244.85 14.6C249.68 14.6 254.05 16.33 257.36 19.35V18.82C257.36 16.48 259.17 14.67 261.51 14.67C263.85 14.67 265.66 16.48 265.66 18.82ZM257.36 35.1V34.57C257.36 27.57 252.24 22.29 245.76 22.29C239.28 22.29 234.15 27.57 234.15 34.57C234.15 41.57 239.27 46.86 245.76 46.86C252.25 46.86 257.06 41.89 257.36 35.1Z"
                    fill="white" />
                <g class="letter">
                    <path
                        d="M217.72 18.82V50.25C217.72 52.59 215.91 54.48 213.57 54.48C211.23 54.48 209.42 52.59 209.42 50.25V18.82C209.42 16.48 211.23 14.67 213.57 14.67C215.91 14.67 217.72 16.48 217.72 18.82Z"
                        fill="white" />
                    <path
                        d="M213.57 10.3C216.276 10.3 218.47 8.1062 218.47 5.4C218.47 2.6938 216.276 0.5 213.57 0.5C210.864 0.5 208.67 2.6938 208.67 5.4C208.67 8.1062 210.864 10.3 213.57 10.3Z"
                        fill="white" />
                </g>
                <path class="letter"
                    d="M201.14 23.27C201.14 25.38 199.4 26.96 197.29 26.96C196.46 26.96 195.41 26.66 194.88 25.6C193.6 24.17 191.79 21.99 188.85 21.99C186.28 21.99 184.63 23.87 184.63 26.06C184.63 29.15 187.79 30.13 191.64 31.41C196.23 32.84 201.44 35.25 201.44 42.79C201.44 49.12 195.93 54.55 188.85 54.55C182.14 54.55 176.26 50.18 176.26 45.5C176.26 43.39 177.92 41.66 180.03 41.66C181.23 41.66 182.29 42.19 182.89 43.17C184.02 44.83 185.61 47.09 188.85 47.09C191.34 47.09 193.14 45.2 193.14 43.02C193.14 39.93 190.28 38.72 186.74 37.51C181.99 35.86 176.64 33.82 176.64 26.06C176.64 19.65 181.91 14.6 188.85 14.6C195.26 14.6 201.14 18.52 201.14 23.27Z"
                    fill="white" />
                <path class="letter"
                    d="M168.27 32.16V50.25C168.27 52.59 166.39 54.48 164.13 54.48C161.87 54.48 159.98 52.59 159.98 50.25V31.86C159.98 25.6 156.14 22.29 151.31 22.29C146.48 22.29 142.64 25.6 142.64 31.86V50.25C142.64 52.59 140.83 54.48 138.5 54.48C136.17 54.48 134.35 52.59 134.35 50.25V32.16C134.35 21.38 141.81 14.6 151.31 14.6C160.81 14.6 168.27 21.38 168.27 32.16Z"
                    fill="white" />
                <g class="letter">
                    <path
                        d="M124.7 18.82V50.25C124.7 52.59 122.9 54.48 120.56 54.48C118.22 54.48 116.41 52.59 116.41 50.25V18.82C116.41 16.48 118.22 14.67 120.56 14.67C122.9 14.67 124.7 16.48 124.7 18.82Z"
                        fill="white" />
                    <path
                        d="M120.56 10.3C123.266 10.3 125.46 8.1062 125.46 5.4C125.46 2.6938 123.266 0.5 120.56 0.5C117.854 0.5 115.66 2.6938 115.66 5.4C115.66 8.1062 117.854 10.3 120.56 10.3Z"
                        fill="white" />
                </g>
            </g>

            <g class="icon">
                <path
                    d="M86.39 80.9C90.01 86.35 86.65 92.84 78.87 95.38L71.84 97.69C64.06 100.23 54.82 97.87 51.19 92.41L43.53 80.89L32.86 64.83L1.96 18.32C-1.67 12.86 1.7 6.37997 9.47 3.82997L16.51 1.52997C24.29 -1.02003 33.53 1.33997 37.15 6.79997L86.39 80.9ZM71.85 91.25C76.79 89.64 78.69 85.49 76.3 81.91L44.23 33.64C41.85 30.05 36.18 28.53 31.24 30.14C26.3 31.76 24.32 35.79 26.71 39.38L58.78 87.64C61.16 91.23 66.91 92.87 71.85 91.25ZM26.78 23.43C32.51 21.55 34.83 16.57 32.14 12.52C29.37 8.34997 22.52 6.50997 16.78 8.37997C11.05 10.26 8.66 15.13 11.42 19.3C14.12 23.35 21.04 25.31 26.78 23.43Z"
                    fill="white" />
                <path
                    d="M80.81 8.72996C86.55 10.6 88.94 15.47 86.17 19.64C83.48 23.69 76.55 25.65 70.82 23.77C65.08 21.9 62.76 16.91 65.46 12.86C68.22 8.69996 75.08 6.84996 80.81 8.72996Z"
                    fill="#66a6e6" />
                <path
                    d="M32.86 64.83L43.53 80.89L35.88 92.4C33.31 96.28 27.41 98.15 22.47 96.54C17.53 94.92 15.78 90.54 18.36 86.67L32.86 64.83Z"
                    fill="#66a6e6" />
                <path
                    d="M70.82 23.77C76.55 25.65 83.48 23.69 86.17 19.64C88.94 15.47 86.55 10.6 80.81 8.72996C75.08 6.84996 68.22 8.69996 65.46 12.86C62.76 16.91 65.08 21.9 70.82 23.77Z"
                    stroke="#66a6e6" stroke-miterlimit="10" />
                <path
                    d="M32.86 64.83L18.36 86.67C15.78 90.54 17.53 94.92 22.47 96.54C27.41 98.15 33.31 96.28 35.88 92.4L43.53 80.89"
                    stroke="#66a6e6" stroke-miterlimit="10" />
                <path
                    d="M43.53 80.89L51.19 92.41C54.82 97.87 64.06 100.23 71.84 97.69L78.87 95.38C86.65 92.84 90.01 86.35 86.39 80.9L37.15 6.79997C33.53 1.33997 24.29 -1.02003 16.51 1.52997L9.47 3.82997C1.7 6.37997 -1.67 12.86 1.96 18.32L32.86 64.83L43.53 80.89Z"
                    stroke="white" stroke-miterlimit="10" />
                <path
                    d="M48.91 72.79L70.55 40.23C73.13 36.35 71.3 32.1 66.36 30.48C61.42 28.86 55.6 30.62 53.02 34.49L38.24 56.73L48.91 72.79Z"
                    fill="#66a6e6" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_115_8">
                <rect width="341.38" height="99.21" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script lang="ts">
import "bootstrap/dist/css/bootstrap.css";

import { defineComponent } from "vue";

import { reactive, toRefs } from "vue";

function animateSvgLetters() {
    let delay: number = 200;

    const paths: NodeListOf<SVGElement> = document.querySelectorAll("svg .letter");
    const num = paths.length;

    paths.forEach((path: SVGElement, index: number) => {
        setTimeout(() => {
            if (index >= 15) {
                path.classList.add("wavy");
            }
        }, (num - index - 1) * 60 + delay);
    });
}

function stopAnimation() {
    const paths: NodeListOf<SVGElement> = document.querySelectorAll("svg .letter");
    paths.forEach((path: SVGElement) => {
        path.classList.remove("wavy");
    })
}

function myEventHandler(e: Event) {
    stopAnimation();
    animateSvgLetters();
}

export default defineComponent({
    name: "Logo",
    components: {},
    setup() {

        const state = reactive({});

        // onMounted(() => {
        //     animateSvgLetters();
        //     window.addEventListener("resize", myEventHandler);
        // });

        // onUnmounted(() => {
        //     stopAnimation();
        //     window.removeEventListener("resize", myEventHandler);
        // });

        return {
            ...toRefs(state),
        };
    },
});
</script>

<style rel="stylesheet" lang="scss">
#my-logo {
    margin: 10px auto 20px;
    max-width: 450px;
    // display: block;
    transition: 0.5s;

    &:hover {
        transform: scale(1.015);
    }

    // animation-name: shift;
    // animation-duration: 5s;
    // animation-timing-function: ease;
    // animation-iteration-count: infinite;
    // position: relative;
    // transform-origin: center;

    .cls-1 {
        stroke: #fff;
    }

    .cls-1,
    .cls-2 {
        fill: none;
        stroke-miterlimit: 10;
    }

    .cls-2 {
        stroke: #66a6e6;
    }

    .cls-3 {
        fill: #66a6e6;
    }

    .cls-3,
    .cls-4 {
        stroke-width: 0px;
    }

    .cls-4 {
        fill: #fff;
    }
}

.wavy {
    animation-name: shift;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    position: relative;
    top: 0;
    left: 0;
}

@keyframes color {
    0% {
        fill: white;
    }

    50% {
        fill: $light-accent;
    }

    100% {
        fill: white;
    }
}

@keyframes shift {
    50% {
        // transform: rotate(3deg) scale(0.96) translateY(5px) translateX(5px);
        transform: scale(0.98);
    }
}

@keyframes wavy {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(0px);
    }
}
</style>
