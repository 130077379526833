
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import { defineComponent } from "vue";

import { ref, onMounted, reactive, toRefs, } from "vue";

export default defineComponent({
    name: "Project",
    props: ["project"],
    components: {},
    setup() {

        const state = reactive({
        });
        const proj = ref(null);

        onMounted(() => { });

        return {
            ...toRefs(state),
            proj,
        };
    },
    computed: {},
});
