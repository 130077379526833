
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import { defineComponent, onMounted, onUnmounted } from "vue";
import { colors } from "./pub";

import { ref, reactive, toRefs } from "vue";
import { FileTextOutlined, LinkOutlined, DesktopOutlined, CodeOutlined, YoutubeOutlined, CameraOutlined } from '@ant-design/icons-vue';

export default defineComponent({
    name: "Publication",
    props: ["publication"],
    components: { FileTextOutlined, LinkOutlined, DesktopOutlined, CodeOutlined, YoutubeOutlined, CameraOutlined },
    setup(props) {
        const state = reactive({
            imageSize: "small",
            clickLink: props.publication.project ? props.publication.project : props.publication.preprint ? props.publication.preprint : "",
        });
        const pub = ref(null);

        const adjustColumns = () => {
            if (window.innerWidth > 1100) {
                state.imageSize = "small";
            } else if (window.innerWidth < 850) {
                state.imageSize = "big";
            } else {
                state.imageSize = "medium";
            }
        }

        onMounted(() => {
            adjustColumns();
            window.addEventListener("resize", adjustColumns);
        });

        onUnmounted(() => {
            adjustColumns();
            window.removeEventListener("resize", adjustColumns);
        });

        return {
            ...toRefs(state),
            pub,
            colors
        };
    },
    computed: {},
});
