
import "bootstrap/dist/css/bootstrap.css";

import { defineComponent } from "vue";

import { reactive, toRefs } from "vue";

import { makeWind } from "./wind";

let d: any;
export default defineComponent({
    name: "Wind",

    mounted() {
        setTimeout(() => {
            makeWind(this.$refs.canvasOutlet as HTMLElement);
        });
    },

    setup() {

        const state = reactive({});

        return {
            ...toRefs(state),
        };
    },
    computed: {}
});
