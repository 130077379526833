
import "bootstrap/dist/css/bootstrap.css";
import * as _ from "underscore";

import { CommentOutlined } from "@ant-design/icons-vue";

import Publication from "./Publication.vue";

import { defineComponent, watch, reactive, toRefs } from "vue";

import { colors } from "./pub";
import { publications, Pub, Tag } from "./pub";

const comparePubs = (a: Pub, b: Pub) => {
    // Compare by year
    return b.year - a.year;
}

publications.sort(comparePubs);

const publicationsByYear = _.chain(publications)
    .groupBy((x) => x.year)
    .pairs()
    .sortBy((x) => -x[0])
    .map((x) => ({
        year: x[0],
        publications: x[1],
    }))
    .value();

export default defineComponent({
    name: "Publications",
    components: { Publication, CommentOutlined },
    setup() {
        const minYear = Math.min(...publications.map(p => p.year));
        const maxYear = Math.max(...publications.map(p => p.year));

        const state = reactive({
            yearFilter: [minYear, maxYear],
            currentTag: "all",
            displayLimit: 10, // initially showing 10 publications
            filteredPublications: publications
        });

        const colorOptions = ["all", ...Object.keys(colors)];

        // Method to show more publications
        function showMore() {
            state.displayLimit += 10; // Increase the limit by 10
            if (state.displayLimit > state.filteredPublications.length) {
                state.displayLimit = state.filteredPublications.length; // Don't exceed total number
            }
        }

        // Method to show fewer publications
        function showLess() {
            state.displayLimit -= 10; // Decrease the limit by 10
            if (state.displayLimit < 10) {
                state.displayLimit = Math.min(10, state.filteredPublications.length); // Don't go below initial limit
            }
        }

        watch(() => [state.yearFilter, state.currentTag],
            () => {
                const yearPublications = publications.filter(p => p.year >= state.yearFilter[0] && p.year <= state.yearFilter[1]);
                state.filteredPublications = state.currentTag === 'all' ? yearPublications : yearPublications.filter(p => p.tags.includes(state.currentTag as Tag));
                state.displayLimit = Math.min(10, state.filteredPublications.length);
            })

        return {
            publications,
            ...toRefs(state),
            colorOptions,
            publicationsByYear,
            minYear,
            maxYear,
            showMore,
            showLess
        };
    },
    computed: {},
});
