
import "bootstrap/dist/css/bootstrap.css";

import { defineComponent, reactive, toRefs, onMounted, onBeforeUnmount } from "vue";

export default defineComponent({
    name: "Nav",
    setup() {
        const state = reactive({
            navShow: false,
            activeSection: "home",
        });

        const headerLogo = require(`@/assets/logos/footerlogo.svg`);
        const harvardLogo = require(`@/assets/logos/harvardlogo.svg`);

        const toggleNavbar = () => {
            state.navShow = !state.navShow;
        };

        const setActiveSection = (section: string) => {
            state.activeSection = section;
            // console.log(section);
        };

        const onScroll = () => {
            const scrollPosition = window.scrollY + 100;

            // const home = document.getElementById("home-sec");
            const themes = document.getElementById("themes");
            const team = document.getElementById("team");
            const pubs = document.getElementById("publications");
            const courses = document.getElementById("courses");
            const contact = document.getElementById("contact");

            // Logic to determine which section is in view
            if (themes && scrollPosition < themes.offsetTop) {
                setActiveSection("home");
            } else if (
                themes &&
                team &&
                scrollPosition >= themes.offsetTop &&
                scrollPosition < team.offsetTop
            ) {
                setActiveSection("themes");
            } else if (
                team &&
                pubs &&
                scrollPosition >= team.offsetTop &&
                scrollPosition < pubs.offsetTop
            ) {
                setActiveSection("team");
            } else if (
                pubs &&
                courses &&
                scrollPosition >= pubs.offsetTop &&
                scrollPosition <
                courses.offsetTop
            ) {
                setActiveSection("pubs");
            } else if (courses && contact && scrollPosition >= courses.offsetTop && scrollPosition <
                Math.min(
                    contact.offsetTop,
                    document.documentElement.offsetHeight -
                    document.documentElement.clientHeight -
                    100
                )) {
                setActiveSection("courses");
            } else {
                setActiveSection("contact");
            }

            if (scrollPosition - 100 > 80) {
                document.querySelectorAll(".navbar-brand img").forEach((el) => {
                    const img = el as HTMLImageElement;
                    img.style.maxHeight = "48px";
                    img.style.minWidth = "160px";
                });

                document.querySelectorAll(".navbar-brand .nav-hide").forEach((el) => {
                    if ((el as any).id === "nav-divider") {

                        (el as any).style.opacity = "0.5";
                    } else {

                        (el as any).style.opacity = "1";
                    }
                    (el as any).style.visibility = "visible";
                    (el as any).style.pointerEvents = "auto";
                    (el as any).style.width = "auto";
                });
            } else {
                document.querySelectorAll(".navbar-brand img").forEach((el) => {
                    const img = el as HTMLImageElement;
                    img.style.maxHeight = "60px";
                    img.style.minWidth = "200px";
                });

                document.querySelectorAll(".navbar-brand .nav-hide").forEach((el) => {
                    (el as any).style.opacity = "0";
                    (el as any).style.visibility = "hidden";
                    (el as any).style.pointerEvents = "none";
                    (el as any).style.width = "0";
                });
            }
        };

        onMounted(() => {
            window.addEventListener("scroll", onScroll);
        });

        onBeforeUnmount(() => {
            window.removeEventListener("scroll", onScroll);
        });

        return {
            ...toRefs(state),
            toggleNavbar,
            setActiveSection,
            onScroll,
            headerLogo,
            harvardLogo,
        };
    },
    computed: {},
});
